import React from "react";
import { PreFooter, Download, Poster, BtnContainer } from "./styles";
import { P, Button } from "../../styles";
// import PDFDownload from "../../Allsorts_Summer-Activity-Poster.pdf";

import PosterJpg from "./Allsorts_Summer-Activity-Poster.jpg";

import { saveAs } from "file-saver";

const downloadPDF = () => {
  fetch("/images/Allsorts_Summer-Activity-Poster.pdf", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Allsorts_Summer-Activity-Poster.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const PreFooterComponent = () => (
  <PreFooter>
    <Download>
      <P>
        Download the poster, print it off at home or work, or order a set of
        pre-printed posters. Tick the activities off with whānau and friends as
        you go along!
      </P>
      <BtnContainer>
        <a>
          <Button onClick={downloadPDF}>Download Poster</Button>
        </a>
        <a
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=O1tTr6C6WEWUfHOBL9740LhcH6p1CRBLrBZuT4BhiTJUN0ZKSUFXQ1BQOElSOVpKODBQQzVKRzdPWSQlQCN0PWcu"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Button>Order Pre-Printed Poster</Button>
        </a>
      </BtnContainer>
    </Download>
    <Poster>
      <img
        src={PosterJpg}
        alt="Poster"
        style={{
          transform: "rotate(2deg)",
          maxHeight: "600px",
          filter: "drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))"
        }}
      />
    </Poster>
  </PreFooter>
);

export default PreFooterComponent;
