import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import dance from "../components/Tiles/16_Have-a-dance.jpg";
import ShareDance from "../components/Tiles/Sharing/16_Have-a-dance_Share.jpg";
import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/Have-a-dance.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Have-a-dance.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Have a dance</title>
      <meta name="description" content="Have a dance" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Have a dance"
      />
      <meta property="og:image" content={ShareDance} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Have a dance</ActivityTitle>
    <ImageBox>
      <img
        src={dance}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>

    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        Dancing combines two things that are guaranteed to make us feel better –
        physical exercise and music! If you need a little pick-me-up, busting
        out some moves to a boppy song is sure to do the trick.
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Fdance">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
