import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import sky from "../components/Tiles/08_Watch-the-sky-change.jpg";
import ShareSky from "../components/Tiles/Sharing/08_Watch-the-sky-change_Share.jpg";
import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/08_Watch-the-sky-change.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "08_Watch-the-sky-change.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Watch the sky change</title>
      <meta name="description" content="Watch the sky change" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Watch the sky change"
      />
      <meta property="og:image" content={ShareSky} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Watch the sky change</ActivityTitle>
    <ImageBox>
      <img
        src={sky}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>
    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        One of the best ways to nourish our wairua/soul is to slow down and take
        notice of the beauty around us. Sometimes, just taking a few minutes out
        of a busy day to look at the sky or admire the sunset can completely
        transform how we feel.
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Fsky">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
