import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import swim from "../components/Tiles/01_Go-for-a-swim.jpg";
import ShareSwim from "../components/Tiles/Sharing/01_Go-for-a-swim_Share.jpg";

import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/go-for-a-swim.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Go_For_A_Swim.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Go for a swim!</title>
      <meta name="description" content="Go for a swim!" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Go for a swim!"
      />
      <meta property="og:image" content={ShareSwim} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Go for a swim!</ActivityTitle>
    <ImageBox>
      <img
        src={swim}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>

    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        Swimming is a fantastic way to move our tinana/body and a proven tool to
        boost the mood. Grab your togs and head to your nearest swimming spot to
        refresh and reset the mind, body and soul.{" "}
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Fswim">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
