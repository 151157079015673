import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import birds from "../components/Tiles/07_Spot-5-different-birds.jpg";
import ShareBirds from "../components/Tiles/Sharing/07_Spot-5-Birds_Share.jpg";
import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/Spot-5-different-birds.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Spot-5-different-birds.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Spot 5 different manu/birds</title>
      <meta name="description" content="Spot 5 different manu/birds" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Spot 5 different manu/birds"
      />
      <meta property="og:image" content={ShareBirds} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Spot 5 different manu/birds</ActivityTitle>
    <ImageBox>
      {" "}
      <img
        src={birds}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>

    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        From the mighty kererū to the friendly pīwakawaka, Aotearoa is home to
        over 200 species of native manu/birds – how lucky are we! Try to spot
        and recognise 5 different birds on your next hīkoi. You might like to
        take photos or write down what you’ve observed, too.
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Fbirds">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
