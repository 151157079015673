import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import barefoot from "../components/Tiles/12_Barefoot.jpg";
import ShareBarefoot from "../components/Tiles/Sharing/12_Barefoot_Share.jpg";
import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/Barefoot.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Barefoot.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Spend Time Barefoot</title>
      <meta name="description" content="Spend Time Barefoot" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Spend Time Barefoot"
      />
      <meta property="og:image" content={ShareBarefoot} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Spend time barefoot</ActivityTitle>
    <ImageBox>
      {" "}
      <img
        src={barefoot}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>

    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        Walking barefoot is wonderfully therapeutic, so ditch the jandals and
        feel the whenua beneath you. Besides, nothing says summer quite like the
        sand between your toes or soft grass under your feet.
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Fbarefoot">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
