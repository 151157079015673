import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import friend from "../components/Tiles/13_Call-an-old-friend.jpg";
import ShareFriend from "../components/Tiles/Sharing/13_Call-an-old-friend_Share.jpg";

import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/Call-an-old-friend.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Call-an-old-friend.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Call an old friend</title>
      <meta name="description" content="Call an old friend" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Call an old friend"
      />
      <meta property="og:image" content={ShareFriend} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Call an old friend</ActivityTitle>
    <ImageBox>
      <img
        src={friend}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>

    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        Reach out to a friend you haven’t heard from in a while - you’ll boost
        not just your own wellbeing, but theirs, too. This could be someone
        special you have lost touch with, or someone you wish you caught up with
        more often. A simple ‘Kei te pēhea koe?’ or ‘How are you?’ will go a
        long way towards brightening their day.{" "}
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Ffriend">
          {" "}
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
