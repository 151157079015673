import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import books from "../components/Tiles/03_Read-a-book.jpg";
import ShareBooks from "../components/Tiles/Sharing/03_Read-a-book_Share.jpg";

import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/Read-a-book.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Read-a-book.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Read a book</title>
      <meta name="description" content="Read a book" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Read a book"
      />
      <meta property="og:image" content={ShareBooks} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Read a book</ActivityTitle>
    <ImageBox>
      {" "}
      <img
        src={books}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>

    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        Research has shown that reading fiction helps us develop empathy and
        critical thinking. Bonus: it can also be incredibly fun and make the
        hours fly by. Why not hit your local library and pick out a new
        page-turner, or dive into an old classic that’s been on your list for
        years?{" "}
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Fbook">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
