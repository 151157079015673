import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import hikoi from "../components/Tiles/04_Go-for-a-hikoi.jpg";
import ShareHikoi from "../components/Tiles/Sharing/04_Go-for-a-hikoi_Share.jpg";
import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/Go-for-a-hikoi.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Go-for-a-hikoi.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Go for a hikoi/wander</title>
      <meta name="description" content="Go for a hikoi/wander" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Go for a hikoi/wander"
      />
      <meta property="og:image" content={ShareHikoi} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Go for a hīkoi/wander</ActivityTitle>
    <ImageBox>
      <img
        src={hikoi}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>

    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        Te taiao/nature is both free to enjoy and absolutely priceless for our
        health and wellbeing. This summer, embark on a hīkoi and explore a new
        part of your local area, either alone or in good company. Pay attention
        to the beautiful surroundings and revel in the sights, smells, and
        sounds.{" "}
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Fhikoi">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
