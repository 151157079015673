import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import adventure from "../components/Tiles/11_Backyard-adventure.jpg";
import ShareBackyard from "../components/Tiles/Sharing/11_Backyard-adventure_Share.jpg";
import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/Backyard-adventure.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Backyard-adventure.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Go on a backyard adventure</title>
      <meta name="description" content="Go on a backyard adventure" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Go on a backyard adventure"
      />
      <meta property="og:image" content={ShareBackyard} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Go on a backyard adventure</ActivityTitle>
    <ImageBox>
      {" "}
      <img
        src={adventure}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>

    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        You don’t need to travel far to experience something new this summer.
        Pitching a tent in your backyard with tamariki for a little ‘wilderness
        adventure’ can be buckets of fun!
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Fbackyard">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
