import React from "react";

import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import whanau from "../components/Tiles/15_Connect-with-whanau.jpg";
import ShareWhanau from "../components/Tiles/Sharing/15_Connect-with-whanau_Share.jpg";

import MetaTags from "react-meta-tags";

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Connect with whānau</title>
      <meta name="description" content="Connect with whānau" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Connect with whānau"
      />
      <meta property="og:image" content={ShareWhanau} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Connect with whānau</ActivityTitle>
    <ImageBox>
      <img
        src={whanau}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>
    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        Humans are hard-wired for connection. It brings purpose and belonging to
        our lives and makes us feel happier and more secure. Whether it’s the
        whānau you were born into or the whānau you chose for yourself, take
        some time today to connect with the people you love and be fully present
        in their company.{" "}
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Fwhanau">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
