import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import Arty from "../components/Tiles/09_Get-arty.jpg";
import ShareArt from "../components/Tiles/Sharing/09_Get-arty_Share.jpg";

import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/get-arty.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "get-arty.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Get Arty</title>
      <meta name="description" content="Get Arty." />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Get Arty"
      />
      <meta property="og:image" content={ShareArt} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Get arty!</ActivityTitle>
    <ImageBox>
      <img
        src={Arty}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>

    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        Whether it’s colouring, painting, dabbling in photography, or something
        else, making art helps us become more creative, lifts our wairua/spirit,
        and calms our nervous system. It doesn’t matter how talented you are –
        your wellbeing practice will be down to a fine art.{" "}
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz/arty">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
