import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import outdoors from "../components/Tiles/10_Play-a-game-outdoors.jpg";
import ShareOutdoors from "../components/Tiles/Sharing/10_Play-a-game-outdoors_Share.jpg";
import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/Play-a-game-outdoors.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Play-a-game-outdoors.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Play a game outdoors</title>
      <meta name="description" content="Play a game outdoors" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Play a game outdoors"
      />
      <meta property="og:image" content={ShareOutdoors} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Play a game outdoors</ActivityTitle>
    <ImageBox>
      <img
        src={outdoors}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>

    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        We know exercise is good for us, but when we mix it with a little bit of
        fun, the effects on our physical and mental wellbeing can be incredible!
        The options are endless – you could give frisbee a go, challenge a
        friend to a game of swing ball, play fetch with your furry friend, or
        anything that strikes your fancy.{" "}
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Foutdoors">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
