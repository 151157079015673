import styled from "styled-components";
import { Description } from "../../styles";

export const Tiles = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 60px 30px;
  padding: 50px 90px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    padding: 30px 12px;
  }
`;

export const Tile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
  & img {
    transition: transform 600ms ease-in-out;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

export const HomeDescription = styled(Description)`
  text-align: center;
  margin: 0 15%;
  grid-column: 1 / span 4;

  @media (max-width: 800px) {
    grid-column: 1 / span 2;
    margin: 0 0;
  }
`;

export const HomeContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
`;
