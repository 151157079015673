import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Description, P } from "../../styles";
import { Tiles, Tile, Overlay, HomeDescription } from "./styles";

import swim from "./01_Go-for-a-swim.jpg";
import kai from "./02_Share-kai.jpg";
import book from "./03_Read-a-book.jpg";
import hikoi from "./04_Go-for-a-hikoi.jpg";
import song from "./05_Learn-a-new-song.jpg";
import picnic from "./06_Have-a-picnic.jpg";
import birds from "./07_Spot-5-different-birds.jpg";
import sky from "./08_Watch-the-sky-change.jpg";
import arty from "./09_Get-arty.jpg";
import outdoors from "./10_Play-a-game-outdoors.jpg";
import adventure from "./11_Backyard-adventure.jpg";
import barefoot from "./12_Barefoot.jpg";
import friend from "./13_Call-an-old-friend.jpg";
import river from "./14_Visit-a-river.jpg";
import whanau from "./15_Connect-with-whanau.jpg";
import dance from "./16_Have-a-dance.jpg";
import scribble from "./Scribble_01.png";

const images = [
  { image: swim, path: "swim" },
  { image: kai, path: "kai" },
  { image: book, path: "book" },
  { image: hikoi, path: "hikoi" },
  { image: song, path: "song" },
  { image: picnic, path: "picnic" },
  { image: birds, path: "birds" },
  { image: sky, path: "sky" },
  { image: arty, path: "arty" },
  { image: outdoors, path: "outdoors" },
  { image: adventure, path: "backyard" },
  { image: barefoot, path: "barefoot" },
  { image: friend, path: "friend" },
  { image: river, path: "visit" },
  { image: whanau, path: "whanau" },
  { image: dance, path: "dance" }
];

const DelayedLink = ({
  delay,
  setTileOpened,
  replace,
  state,
  to,
  ...props
}) => {
  const navigate = useNavigate();
  const timerRef = useRef();

  useEffect(() => () => clearTimeout(timerRef.current), []);

  const clickHandler = (e) => {
    e.preventDefault();
    setTileOpened(to);
    timerRef.current = setTimeout(navigate, delay, to, { replace, state });
  };

  return <Link to={to} {...props} onClick={clickHandler} />;
};

const TilesComponent = ({ isTileOpened, setTileOpened }) => (
  <Tiles>
    <HomeDescription>
      <P style={{ gridColumn: "3 / span 8" }}>
        It’s often the simple, everyday things that can bring us the most joy
        and create lifelong memories. This summer, why not explore all sorts of
        ways to boost your wellbeing, connect with the people you love, and try
        something new? Here are some ideas if you need a little inspiration -
        just{" "}
        <span style={{ fontWeight: 700 }}>
          click on any tile below to learn more
        </span>
        , then give it a go! You can also download or order the printed poster
        and tick off the activities with whānau and friends as you go along.
      </P>
    </HomeDescription>
    {images.map(({ image, path }, index) => (
      <DelayedLink
        delay={100}
        setTileOpened={setTileOpened}
        to={path}
        key={`tile-${index}`}
      >
        <Tile>
          <img
            alt=""
            src={image}
            style={{
              alignSelf: "center",
              height: "100%",
              width: "100%"
            }}
          />
        </Tile>
      </DelayedLink>
    ))}
  </Tiles>
);

const TileContainer = () => {
  let storageTiles = localStorage.getItem("openedTiles");

  const [openedTiles, setOpenedTiles] = useState(
    storageTiles ? JSON.parse(storageTiles) : []
  );

  const setTileOpened = (tile) => {
    if (!isTileOpened(tile)) {
      const tiles = [...openedTiles, tile];

      setOpenedTiles(tiles);
      localStorage.setItem("openedTiles", JSON.stringify(tiles));
    }
  };

  const isTileOpened = (tile) => {
    return openedTiles.indexOf(tile) !== -1;
  };

  return (
    <div>
      <TilesComponent
        setTileOpened={setTileOpened}
        isTileOpened={isTileOpened}
      />
    </div>
  );
};

export default TileContainer;
