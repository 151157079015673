import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import visit from "../components/Tiles/14_Visit-a-river.jpg";
import ShareVisit from "../components/Tiles/Sharing/14_Visit-a-river_Share.jpg";

import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/14_Visit-a-river.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "14_Visit-a-river.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>
        All Sorts of Summer Wellbeing | Visit a river, beach or hill
      </title>
      <meta name="description" content="Visit a river, beach or hill" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Visit a river, beach or hill"
      />
      <meta property="og:image" content={ShareVisit} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Visit a river, beach or hill</ActivityTitle>
    <ImageBox>
      {" "}
      <img
        src={visit}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>

    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        Spending time in te taiao/nature perks up our mood, improves our immune
        system, and melts the stress away. And when it comes to nature in
        Aotearoa, we are certainly spoilt for choice! Wherever you are, a
        beautiful river, beach, or hill is likely within reach, just waiting to
        be (re)discovered.{" "}
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Fvisit">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
