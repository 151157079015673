import React from "react";
import { Description } from "../styles";
import {
  Activity,
  ImageBox,
  ActivityTitle,
  ActivityButton,
  Back,
  BtnBox,
  ActivityDescription
} from "../components/Activity/styles";
import picnic from "../components/Tiles/06_Have-a-picnic.jpg";
import SharePicnic from "../components/Tiles/Sharing/06_Have-a-picnic_Share.jpg";

import MetaTags from "react-meta-tags";

const downloadImage = () => {
  fetch("/images/06_Have-a-picnic.jpg", {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "06_Have-a-picnic.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityComponent = () => (
  <Activity>
    <MetaTags>
      <title>All Sorts of Summer Wellbeing | Have a picnic</title>
      <meta name="description" content="Have a picnic" />
      <meta
        property="og:title"
        content="All Sorts of Summer Wellbeing | Have a picnic"
      />
      <meta property="og:image" content={SharePicnic} />
    </MetaTags>
    <Back to="/">Back</Back>
    <ActivityTitle>Have a picnic</ActivityTitle>
    <ImageBox>
      {" "}
      <img
        src={picnic}
        alt=""
        style={{
          alignSelf: "center",
          height: "100%",
          width: "100%"
        }}
      />
    </ImageBox>
    <ActivityDescription>
      <p style={{ marginTop: "0" }}>
        Spread out a blanket, breathe in the fresh air, soak up the sun, and
        enjoy some tasty kai! Whether it’s at the beach, in a local park or your
        own backyard, a summer picnic is sure to lift the mood.{" "}
      </p>
      <BtnBox>
        <a href="https://www.facebook.com/sharer/sharer.php?u=summer.allsorts.org.nz%2Fpicnic">
          <ActivityButton>Share on Facebook</ActivityButton>
        </a>
      </BtnBox>
    </ActivityDescription>
  </Activity>
);

export default ActivityComponent;
